import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { ArrowLink } from '@/components/ArrowLink';

import styles from './styles.module.scss';

type FeatureProps = {
  feature: {
    title: string;
    description: string;
    image: ReactNode;
    link: {
      text: string;
      href: string;
    };
  };
  fullWidth?: boolean;
};

export const Feature: FC<FeatureProps> = ({ feature, fullWidth }) => {
  return (
    <Box
      className={clsx(styles.feature, {
        [styles.fullWidth]: fullWidth,
      })}
    >
      <Box className={styles.featureImage}>{feature.image}</Box>
      <Typography variant="h3" className={styles.featureTitle}>
        {feature.title}
      </Typography>
      <Typography variant="subtitle2" className={styles.featureDescription}>
        {feature.description}
      </Typography>
      <ArrowLink href={feature.link.href} text={feature.link.text} />
    </Box>
  );
};
