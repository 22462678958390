import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import { StoryCard, SuccessStoriesCatalog } from '@/features/successStories';
import { ROUTES } from '@/libs/routing';
import { ArrowLink, Container } from '@/components';
import styles from './styles.module.scss';

const ConfigBySulg: { [key: string]: StoryCard['image'] } = {
  talentedge: {
    src: '/images/successStories/talentedgeLogo.png',
    color: '#152f46',
    width: 278,
    height: 52,
  },
  RangeTeller: {
    src: '/images/successStories/RangeTellerLogo.png',
    color: '#3447ca',
    width: 278,
    height: 48,
  },
  moblers: {
    src: '/images/successStories/moblersLogo.png',
    color: '#0e2544',
    width: 242,
    height: 86,
  },
};

export const SuccessStoriesSection: FC = () => {
  const { t: tSuccessStories } = useTranslation('successStories');
  const storiesConfig = tSuccessStories(`stories`, { returnObjects: true });
  const items: StoryCard[] = Object.entries(storiesConfig)
    .filter(([slug]) => ConfigBySulg[slug])
    .map(([slug, story]) => ({
      slug,
      title: story.preview.title,
      description: story.preview.description,
      image: ConfigBySulg[slug],
    }));

  return (
    <Box component={'section'} className={styles.section}>
      <Container className={styles.container}>
        <Typography variant={'h2'} className={styles.title}>
          <Trans
            ns="home"
            i18nKey="successStories.title"
            components={{
              Pw: <Box component="span" className={styles.pwMention} />,
            }}
          />
        </Typography>
        <SuccessStoriesCatalog items={items} />
        <ArrowLink
          href={ROUTES.SUCCESS_STORIES}
          text={<Trans ns="home" i18nKey="successStories.linkText" />}
          className={styles.link}
        />
      </Container>
    </Box>
  );
};
