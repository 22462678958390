import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './styles.module.scss';
import { Container, LazyLottie } from '@/components';
import { SectionButtons } from '../SectionButtons';
import { Trans, useTranslation } from 'next-i18next';
import animationPreview from '/public/images/home/animationPreview4.webp';
import { ROUTES } from '@/libs/routing';

export const AccurateDataSection: FC = () => {
  const { t } = useTranslation('home', { keyPrefix: 'accurateData' });

  return (
    <Box className={styles.section}>
      <Typography variant="h2" className={styles.title}>
        {t('title')}
      </Typography>
      <Container className={styles.container}>
        <Box className={styles.image}>
          <LazyLottie
            dataSrc="/lotties/home/animation4.json"
            previewSrc={animationPreview}
            previewProps={{ alt: '', loading: 'lazy' }}
          />
        </Box>
        <Box className={styles.content}>
          <Typography variant="h3" className={styles.contentTitle}>
            {t('contentTitle')}
          </Typography>

          <Typography variant="body1" className={styles.contentStep}>
            <Trans t={t} i18nKey="steps.1" components={{ span: <span /> }} />
          </Typography>

          <Typography variant="body1" className={styles.contentStep}>
            <Trans t={t} i18nKey="steps.2" components={{ span: <span /> }} />
          </Typography>

          <Typography variant="body1" className={styles.contentStep}>
            <Trans t={t} i18nKey="steps.3" components={{ span: <span /> }} />
          </Typography>
          <SectionButtons learmMoreHref={ROUTES.OUR_DATA} />
        </Box>
      </Container>
    </Box>
  );
};
