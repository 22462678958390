import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import {
  CustomerFeedbackSection,
  HeadSection,
  LineBackground,
  StartFreeSection,
} from '@/components';
import {
  AccurateDataSection,
  GetEmailsAndDailsSection,
  HeadImage,
  HittingNumberSection,
  SuccessStoriesSection,
  UniqueFeaturesSection,
  UnlimitedLookupsSection,
} from './components';

export const Home: FC = () => {
  const { t } = useTranslation('home');

  return (
    <>
      <HeadSection
        title={t('head.title')}
        subtitle={t('head.subtitle')}
        background={<LineBackground />}
        image={<HeadImage />}
      />
      <GetEmailsAndDailsSection />
      <UnlimitedLookupsSection />
      <HittingNumberSection />
      <AccurateDataSection />
      <UniqueFeaturesSection />
      <SuccessStoriesSection />
      <CustomerFeedbackSection />
      <StartFreeSection title={t('startFree.title')} subtitle={t('startFree.subtitle')} />
    </>
  );
};
