import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { MetaKeytoRouteDictionary } from './MetaKeytoRouteDictionary';

export const MetaData: FC = () => {
  const router = useRouter();
  const { t } = useTranslation('metaData', {
    keyPrefix: MetaKeytoRouteDictionary[router.pathname],
  });

  const ThirdPartyMetaComponent = MetaKeytoRouteDictionary[router.pathname] ? (
    <>
      <title>{t('title')}</title>
      <meta name="twitter:title" content={t('title')} />
      <meta name="og:title" content={t('title')} />
      <meta name="twitter:description" content={t('description')} />
      <meta name="og:description" content={t('description')} />
      <meta name="description" content={t('description')} key="desc" />
      <meta name="og:url" content={process.env.NEXT_PUBLIC_SITE_URL} />
      <meta name="og:site_name" content={'powerlead.com'} />
      <meta
        property="og:image:url"
        content={`${process.env.NEXT_PUBLIC_SITE_URL}/images/common/og.png`}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content="logo" />
      <meta property="og:type" content="website" />
      <meta
        name="twitter:image"
        content={`${process.env.NEXT_PUBLIC_SITE_URL}/images/common/og.png`}
      />
      <meta name="twitter:card" content="summary_large_image" />
    </>
  ) : null;

  return (
    <Head>
      {ThirdPartyMetaComponent}
      <link rel="icon" href="/favicon/favicon.ico" sizes="48x48" />
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
      <link rel="manifest" href="/manifest.webmanifest" />
    </Head>
  );
};
