import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { Container, LazyLottie } from '@/components';
import { ROUTES } from '@/libs/routing';
import animationPreview from '/public/images/home/animationPreview2.webp';
import { SectionButtons } from '../SectionButtons';
import styles from './styles.module.scss';

export const GetEmailsAndDailsSection: FC = () => {
  const { t } = useTranslation('home', { keyPrefix: 'emailsAndDails' });

  return (
    <Box component="section" className={styles.section}>
      <Container className={styles.container}>
        <Box className={styles.image}>
          <LazyLottie
            dataSrc="/lotties/home/animation2.json"
            previewSrc={animationPreview}
            previewProps={{ alt: '', loading: 'lazy' }}
          />
        </Box>
        <Box className={styles.content}>
          <Typography variant="h2" className={styles.title}>
            {t('title')}
          </Typography>
          <Typography variant="body1" className={styles.description}>
            {t('description')}
          </Typography>
          <SectionButtons learmMoreHref={ROUTES.OUR_DATA} />
        </Box>
      </Container>
    </Box>
  );
};
