import { IStatEntity } from '@/features/home/domain';
import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { CountUp } from 'use-count-up';
import { useInView } from 'react-intersection-observer';

type StatsProps = {
  stats: IStatEntity[];
};

export const Stats: FC<StatsProps> = ({ stats }) => {
  const { ref, inView } = useInView();
  const [animateCounting, setAnimateCounting] = useState(inView);

  useEffect(() => {
    inView && setAnimateCounting(true);
  }, [inView]);
  return (
    <Box className={styles.stats} ref={ref}>
      {stats.map((imte) => (
        <Box key={imte.id} className={styles.statsBlock}>
          <Box className={styles.statsBlockTitleWrapper}>
            <Typography variant="h3" className={styles.statsBlockTitle}>
              <CountUp
                isCounting={animateCounting}
                start={0}
                end={parseFloat(imte.title.text)}
                duration={2}
              />
              <span>{imte.title.symbol}</span>
            </Typography>
          </Box>
          <Typography variant="subtitle1" className={styles.statsBlockDescription}>
            {imte.description}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
