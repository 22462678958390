import { FC } from 'react';
import { useTranslation, Trans } from 'next-i18next';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { StartFreeSection } from '@/components/StartFreeSection';
import { sofiaPro } from '@/libs/fonts';
import { Container } from '@/components';
import { Headline, Catalog } from './components';
import styles from './styles.module.scss';
import { StoryCard } from './types';

type SuccessStoriesProps = {
  items: StoryCard[];
};

export const SuccessStories: FC<SuccessStoriesProps> = ({ items }) => {
  const { t } = useTranslation('successStories');
  return (
    <Box className={clsx(styles.wrapper, sofiaPro.className)}>
      <Box className={styles.headline}>
        <Headline />
      </Box>
      <Box className={styles.catalog}>
        <Container>
          <Catalog items={items} />
        </Container>
      </Box>
      <Box component="section" className={styles.startFree}>
        <StartFreeSection
          title={
            <Trans
              t={t}
              i18nKey="startFree.title"
              components={{
                br: <br />,
              }}
            />
          }
          subtitle={t('startFree.subtitle')}
        />
      </Box>
    </Box>
  );
};
